$(document).ready(function(e) {
	 var APP_ID = '1329604957232462';  
	 window.fbAsyncInit = function() {
		FB.init({
		  appId      : APP_ID,
		  cookie     : true,
		  xfbml      : true,
		  version    : 'v7.0'
		});
		FB.AppEvents.logPageView();   
	  };
});

function facebookLogin(){
	FB.login(function(response) {
		var fbuid = response.authResponse.userID;
		if (response.authResponse) {
		 FB.api('/me', { locale: 'en_US', fields: 'name, email' }, function(response) {
			console.log(JSON.stringify(response));
			 var name = response.name;
			 var email = response.email;
		 });
		}else{
		
		}
	}, {scope:'email'});
}

(function(d, s, id){
	var js, fjs = d.getElementsByTagName(s)[0];
	if (d.getElementById(id)) {return;}
	js = d.createElement(s); js.id = id;
	js.src = "https://connect.facebook.net/en_US/sdk.js";
	fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));